export const defaultMenuType = 'menu-sub-hidden' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = '/app/dashboards/default'
export const searchPath = '/app/pages/search'
export const buyUrl = ''

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'nl'
export const defaultDirection = 'ltr'
export const localeOptions = [
	{ id: 'en', name: 'English', direction: 'ltr' },
	// { id: 'nl', name: 'Nederlands', direction: 'ltr' },
]
export const countryOptions = [
	{ id: 'nl', name: 'Nederland' },
]
export const defaultCountry = { id: 'en', name: 'English' }

export const currentUser = {
	/*
	id: 1,
	title: 'Guest',
	img: '/assets/img/profile-pic-l.jpg',
	date: 'Last seen today 15:24',
	*/
}

export const defaultColor = 'light.blue'
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
]
