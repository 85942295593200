import { currentUser } from '@/constants/config'
import { functions } from '@/mixins/functions'
import app from '../../main'

export default {
	state: {
		currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
		loginError: null,
		processing: false,
		waitfor2fa: false,
		apiURL: localStorage.getItem('apiURL') != null ? localStorage.getItem('apiURL') : process.env.VUE_APP_API_URL
	},
	getters: {
		currentUser: state => state.currentUser,
		processing: state => state.processing,
		loginError: state => state.loginError,
		apiURL: state => state.apiURL,
		waitfor2fa: state => state.waitfor2fa
	},
	mutations: {
		setUser (state, payload) {
			state.currentUser = payload
			state.processing = false
			state.waitfor2fa = false
			state.loginError = null
			localStorage.setItem('user', JSON.stringify(payload))
		},
		setLogout (state) {
			state.currentUser = null
			state.processing = false
			state.loginError = null
		},
		setProcessing (state, payload) {
			state.processing = payload
			state.loginError = null
		},
		setError (state, payload) {
			state.loginError = payload
			state.currentUser = null
			state.processing = false
		},
		setWaitfor2fa (state, payload) {
			state.waitfor2fa = payload
		},
		clearError (state) {
			state.loginError = null
		}
	},
	actions: {
		recoverPassword ({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				let frmRecovery = {};
				if (payload.token) {
					frmRecovery = {
						email: payload.email,
						token: payload.token,
						password: payload.password,
						password_confirmation: payload.password_confirm,
					}
				} else {
					frmRecovery = {
						email: payload.email,
						username: payload.email,
					}
				}
				
				var recoveryUrl = '/user/recovery';
				if (payload.token) {
					recoveryUrl = '/user/recovery/' + payload.token;
				}

				functions.methods.req('POST', recoveryUrl, frmRecovery).then((response) => {
					if (response.status) {
						if (payload.token) {
							app._context.config.globalProperties.$swal.fire({
								icon: 'success',
								title: 'Je wachtwoord is aangepast',
								text: 'Je kan nu inloggen met je nieuwe wachtwoord',
								button: true,
								closeOnClickOutside: false,
							});
						} else {
							app._context.config.globalProperties.$swal.fire({
								icon: 'success',
								title: 'E-mail verzonden',
								text: 'Je zult een e-mail ontvangen met daarin de volgende stap',
								button: true,
								closeOnClickOutside: false,
							});
						}
						resolve();
					} else if (response.message) {
						var strMessage = "We hebben je wachtwoord niet kunnen resetten";
						switch (response.message) {
							case 'messages.user.invalid.password_too_short':
								strMessage = "Het wachtwoord is te kort";
								break;
							case 'messages.user.invalid.password_without_number':
								strMessage = "Het wachtwoord bevat geen nummer";
								break;
							case 'messages.user.invalid.password_without_letter':
								strMessage = "Het wachtwoord bevat geen letter";
								break;
							case 'messages.user.not.found':
								strMessage = "E-mailadres is niet gevonden";
								break;
						}
						app._context.config.globalProperties.$swal.fire({
							icon: 'error',
							title: 'Het wachtwoord resetten is mislukt',
							text: strMessage,
							button: true,
							closeOnClickOutside: false,
						});
						reject();
					} else {
						reject();
					}
				}, (error) => {
					app._context.config.globalProperties.$swal.fire({
						icon: 'error',
						title: 'Wachtwoord resetten mislukt',
						text: "We kunnen je wachtwoord niet resetten",
						button: true,
						closeOnClickOutside: false,
					});
					reject(error);
				});
			});
		},
		logout ({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				functions.methods.req('GET', '/user/logout').then((response) => {
					if (response.status) {
						commit('setLogout');
						resolve();
					} else {
						reject();
					}
				}, (error) => {
					reject(error);
				});
			});
		},
		check ({ commit, getters }, payload) {
			//write API url to storage
			if(getters.apiURL !== false){
				functions.methods.req('GET', '/user/check').then((response) => {
					if (response.status) {
						commit('setUser', response.data);
					} else {
						commit('setLogout');
						window.location.href = '/page/user/login';
					}
				}, (error) => {
					app._context.config.globalProperties.$swal.fire({
						icon: 'error',
						title: 'API foutmelding',
						text: 'We kunnen geen verbinding maken met de API',
						button: false,
						closeOnClickOutside: false,
					});
				});
			}
		},
		login ({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				commit('clearError')
				commit('setProcessing', true)
				let frmLogin = {
					'username': payload.email,
					'email': payload.email,
					'password': payload.password,
				};
				functions.methods.req('POST', '/user/login', frmLogin).then(response => {
					if(response.status){
						commit('setUser', response.data);
						resolve();
					}else{
						//set strError
						var strError;
						strError = '';
						//check 2fa
						console.log(app);
						if (response.message) {
							switch (response.message) {
								case 'twofactor.authentication.waiting':
									commit('setWaitfor2fa', true);
									strError = 'Accepteer het 2FA request';
									app._context.config.globalProperties.$swal.fire({
										title: 'Wachten',
										text: strError,
										icon: 'info',
									});
									break;
								case 'twofactor.authentication.failed':
									commit('setWaitfor2fa', false);
									strError = 'Two-factor-authentication mislukt';
									app._context.config.globalProperties.$swal.fire('Foutmelding', strError, 'error');
									break;
								default:
									commit('setWaitfor2fa', false);
									strError = 'Incorrect credentials!';
									strError = 'Verkeerde inloggegevens!';
									app._context.config.globalProperties.$swal.fire('Foutmelding', strError, 'error');
									break;
							}
						}else{
							commit('setWaitfor2fa', false);
							strError = 'Verkeerde inloggegevens!';
							app._context.config.globalProperties.$swal.fire('Foutmelding', strError, 'error');
						}

						//set error
						commit('setError', strError);
					}
				}, error => {
					app._context.config.globalProperties.$swal.fire('Foutmelding', 'API foutmelding', 'error');
				});
			});
		},
	}
}
