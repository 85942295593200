import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		beforeEnter: AuthRequired,
		children: [
			{
				path: 'page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
			},
			{
				name: 'users',
				path: '/page/app/users/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users')
			},
			{
				name: 'connections',
				path: '/page/app/connections',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/connections')
			},
			{
				name: 'organizations',
				path: '/page/app/organizations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/organizations')
			},
			{
				name: 'admin',
				path: '/page/app/admin',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/admin')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
